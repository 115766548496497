// put your global styles here
@font-face {
  font-family: "TheSansSemiLight";
  font-display: swap;
  src: url(./assets/fonts/TheSansSemiLight.woff) format("woff2");
}

@font-face {
  font-family: "TheSansBold";
  font-display: swap;
  src: url(./assets/fonts/TheSansBold.woff) format("woff2");
}

@font-face {
  font-family: "TheSans-Light";
  src: url(./assets/fonts/TheSans_LP_CYR_ExtraLight.woff) format("woff");
}

@font-face {
  font-family: "TheSans-Regular";
  src: url(./assets/fonts/TheSans_LP_CYR_Regular.woff) format("woff");
}

body {
  // apply default font styles everywhere, Material UI does not do this for you
  font-family: "TheSansSemiLight", sans-serif !important; // theme.font.regular
  font-size: 15px !important; // theme.fontSize.m
}

html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  overflow: auto;
  background-color: white;
}

a:visited {
  color: inherit;
}
